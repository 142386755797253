var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsContainer',{attrs:{"loading":_vm.loading}},[_c('VsSectionHeader',{staticClass:"vs-mb-8",attrs:{"heading":"Notifiche"}}),_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('div',{staticClass:"vs-mb-6"},[_c('VsCheckbox',{attrs:{"labelHidden":"","size":"large","text":"Raggiungimento soglia di crediti Email rimanenti"},model:{value:(_vm.notifications.creditEmailReached.active),callback:function ($$v) {_vm.$set(_vm.notifications.creditEmailReached, "active", $$v)},expression:"notifications.creditEmailReached.active"}}),(_vm.notifications.creditEmailReached.active)?_c('div',{staticClass:"vs-flex-col vs-flex md:vs-flex-row vs-gap-4 vs-pl-10 vs-mt-4"},[_c('ValidationProvider',{attrs:{"name":"Soglia","slim":"","rules":{required: true, min_value: 0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{attrs:{"size":"small","label":"Soglia","min":"0","step":"1","error":errors.length > 0,"errorMessage":errors[0],"variant":"number","type":"number"},model:{value:(_vm.notifications.creditEmailReached.value),callback:function ($$v) {_vm.$set(_vm.notifications.creditEmailReached, "value", _vm._n($$v))},expression:"notifications.creditEmailReached.value"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Indirizzo email","vid":"notifications.creditEmailReached.to","slim":"","rules":{required: true, email: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{attrs:{"size":"small","label":"Indirizzo email","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.notifications.creditEmailReached.to[0]),callback:function ($$v) {_vm.$set(_vm.notifications.creditEmailReached.to, 0, $$v)},expression:"notifications.creditEmailReached.to[0]"}})]}}],null,true)})],1):_vm._e()],1),_c('div',{staticClass:"vs-mb-6"},[_c('VsCheckbox',{attrs:{"labelHidden":"","size":"large","text":"Raggiungimento soglia di crediti Sms rimanenti"},model:{value:(_vm.notifications.creditSmsReached.active),callback:function ($$v) {_vm.$set(_vm.notifications.creditSmsReached, "active", $$v)},expression:"notifications.creditSmsReached.active"}}),(_vm.notifications.creditSmsReached.active)?_c('div',{staticClass:"vs-flex vs-flex-col md:vs-flex-row vs-gap-4 vs-pl-10 vs-mt-4"},[_c('ValidationProvider',{attrs:{"name":"Soglia","slim":"","rules":{required: true, min_value: 0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{attrs:{"size":"small","label":"Soglia","min":"0","step":"1","error":errors.length > 0,"errorMessage":errors[0],"variant":"number","type":"number"},model:{value:(_vm.notifications.creditSmsReached.value),callback:function ($$v) {_vm.$set(_vm.notifications.creditSmsReached, "value", _vm._n($$v))},expression:"notifications.creditSmsReached.value"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Indirizzo email","slim":"","vid":"notifications.creditSmsReached.to","rules":{required: true, email: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{attrs:{"size":"small","label":"Indirizzo email","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.notifications.creditSmsReached.to[0]),callback:function ($$v) {_vm.$set(_vm.notifications.creditSmsReached.to, 0, $$v)},expression:"notifications.creditSmsReached.to[0]"}})]}}],null,true)})],1):_vm._e()],1),_c('div',{staticClass:"vs-mb-6"},[_c('VsCheckbox',{attrs:{"labelHidden":"","size":"large","text":"Invia una notifica quando un utente crea una lista"},model:{value:(_vm.notifications.recipientCreated.active),callback:function ($$v) {_vm.$set(_vm.notifications.recipientCreated, "active", $$v)},expression:"notifications.recipientCreated.active"}}),(_vm.notifications.recipientCreated.active)?_c('div',{staticClass:"vs-flex vs-flex-col md:vs-flex-row vs-gap-4 vs-pl-10 vs-mt-4"},[_c('ValidationProvider',{attrs:{"name":"Indirizzo email","slim":"","vid":"notifications.recipientCreated.to","rules":{required: true, email: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{attrs:{"size":"small","label":"Indirizzo email","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.notifications.recipientCreated.to[0]),callback:function ($$v) {_vm.$set(_vm.notifications.recipientCreated.to, 0, $$v)},expression:"notifications.recipientCreated.to[0]"}})]}}],null,true)})],1):_vm._e()],1),_c('div',{staticClass:"vs-mb-6"},[_c('VsCheckbox',{attrs:{"labelHidden":"","size":"large","text":"Invia una notifica quando un utente carica dei contatti"},model:{value:(_vm.notifications.subscribersImported.active),callback:function ($$v) {_vm.$set(_vm.notifications.subscribersImported, "active", $$v)},expression:"notifications.subscribersImported.active"}}),(_vm.notifications.subscribersImported.active)?_c('div',{staticClass:"vs-flex vs-flex-col md:vs-flex-row vs-gap-4 vs-pl-10 vs-mt-4"},[_c('ValidationProvider',{attrs:{"name":"Indirizzo email","slim":"","vid":"notifications.subscribersImported.to","rules":{required: true, email: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{attrs:{"size":"small","label":"Indirizzo email","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.notifications.subscribersImported.to[0]),callback:function ($$v) {_vm.$set(_vm.notifications.subscribersImported.to, 0, $$v)},expression:"notifications.subscribersImported.to[0]"}})]}}],null,true)})],1):_vm._e()],1),_c('div',{staticClass:"vs-mb-6"},[_c('VsCheckbox',{attrs:{"labelHidden":"","size":"large","text":"Invia una notifica quando la campagna di un utente viene messa in pausa"},model:{value:(_vm.notifications.campaignPaused.active),callback:function ($$v) {_vm.$set(_vm.notifications.campaignPaused, "active", $$v)},expression:"notifications.campaignPaused.active"}}),(_vm.notifications.campaignPaused.active)?_c('div',{staticClass:"vs-flex vs-flex-col md:vs-flex-row vs-gap-4 vs-pl-10 vs-mt-4"},[_c('ValidationProvider',{attrs:{"name":"Indirizzo email","slim":"","vid":"notifications.campaignPaused.to","rules":{required: true, email: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{attrs:{"size":"small","label":"Indirizzo email","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.notifications.campaignPaused.to[0]),callback:function ($$v) {_vm.$set(_vm.notifications.campaignPaused.to, 0, $$v)},expression:"notifications.campaignPaused.to[0]"}})]}}],null,true)})],1):_vm._e()],1),_c('VsButton',{on:{"click":function($event){return passes(_vm.saveNotifications)}}},[_vm._v(" Salva ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }