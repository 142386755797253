











































































































































































































import { Component, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import { UserModule } from '@/store/modules/user'
import { updateManagerNotifications, getManagerNotifications } from '@/api/consoleApi/manager'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'

export enum EnumNotificationType {
    recipientCreated = 'recipientCreated',
    subscribersImported = 'subscribersImported',
    campaignPaused = 'campaignPaused',
    creditEmailReached = 'creditEmailReached',
    creditSmsReached = 'creditSmsReached',
}

@Component({
    name: 'UsersIndex',
    components: {
        VsContainer,
        VsLoader,
        VsSectionHeader,
    },
})
export default class extends Vue {
    loading = false
    users: any[] = []
    total = 0
    notifications = {
        recipientCreated: {
            active: false,
            to: [],
            type: EnumNotificationType.recipientCreated,
            value: 0,
        },
        subscribersImported: {
            active: false,
            to: [],
            type: EnumNotificationType.subscribersImported,
            value: 0,
        },
        campaignPaused: {
            active: false,
            to: [],
            type: EnumNotificationType.campaignPaused,
            value: 0,
        },
        creditEmailReached: {
            active: false,
            to: [],
            type: EnumNotificationType.creditEmailReached,
            value: 1,
        },
        creditSmsReached: {
            active: false,
            to: [],
            type: EnumNotificationType.creditSmsReached,
            value: 1,
        },
    }

    $refs!: any

    get limitSelect () {
        return [
            {
                value: 'percentage',
                label: 'Percentuale',
            },
            {
                value: 'absolute',
                label: 'Assoluto',
            },
        ]
    }

    get user () {
        return UserModule.user
    }

    mounted () {
        this.getNotifications()
    }

    private async getNotifications () {
        this.loading = true
        try {
            const resp = await getManagerNotifications()
            this.notifications.creditEmailReached = resp.data.data.find((el: any) => el.type === EnumNotificationType.creditEmailReached) || this.getDefaultSetting(EnumNotificationType.creditEmailReached)
            this.notifications.creditSmsReached = resp.data.data.find((el: any) => el.type === EnumNotificationType.creditSmsReached) || this.getDefaultSetting(EnumNotificationType.creditSmsReached)
            this.notifications.recipientCreated = resp.data.data.find((el: any) => el.type === EnumNotificationType.recipientCreated) || this.getDefaultSetting(EnumNotificationType.recipientCreated)
            this.notifications.recipientCreated.value = 0
            this.notifications.subscribersImported = resp.data.data.find((el: any) => el.type === EnumNotificationType.subscribersImported) || this.getDefaultSetting(EnumNotificationType.subscribersImported)
            this.notifications.subscribersImported.value = 0
            this.notifications.campaignPaused = resp.data.data.find((el: any) => el.type === EnumNotificationType.campaignPaused) || this.getDefaultSetting(EnumNotificationType.campaignPaused)
            this.notifications.campaignPaused.value = 0
        } catch (e) {
            console.log(e)
        }
        this.loading = false
    }

    private async saveNotifications () {
        this.loading = true
        try {
            await updateManagerNotifications([
                this.notifications.creditEmailReached,
                this.notifications.creditSmsReached,
                this.notifications.recipientCreated,
                this.notifications.subscribersImported,
                this.notifications.campaignPaused,
            ])
            this.$root.$vsToast({
                heading: 'Impostazioni salvate con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante il salvataggio',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    private getDefaultSetting (settingName: string) {
        return {
            active: false,
            to: [this.user.email],
            type: settingName,
            value: 0,
        }
    }
}
